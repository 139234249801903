import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Image } from "@nextui-org/react";

import { HamburgerLineIcon } from "assets/svgs/icons/hamburger-line-icon";
import { NotificationIcon } from "assets/svgs/icons/notification-icon";
import { HelpOutlineIcon } from "assets/svgs/icons/help-outline-icon";
import { LogoColoured } from "assets/svgs/logo-coloured";
import ChatHelp from "modules/dealer/components/chathelp/ChatHelp";
import Notification from "modules/dealer/components/notification/Notification";
import ProfileDropdown from "./component/profile-dropdown";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownSection,
  DropdownItem,
} from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserProfilePicture } from "services";
import { getUserId } from "utils/get-user-id";
import { env } from "config/env";
import Loading from "components/Loading/Loading";
import { useWindowSize } from "usehooks-ts";
import NewChatBotTurbo from "pages/public/landing/components/NewChatBotTurbo";
import { SearchField } from "components/search-field";
import cometd, { initCometD } from "./component/cometD";
import { RootState } from "modules/dealer/store/store";
import { changeTicketView } from "modules/dealer/store/user-information";
const notifications = [
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
  {
    title: "Pending Integration",
    message: "Pending Integration",
  },
];

interface Props {
  handleToggle?: () => void;
  toggle?: boolean;
}
export default function Header({ handleToggle, toggle }: Props) {
  const [isHover, setHover] = useState(false);
  const [chatToggle, setChatToggle] = useState(false);
  const [notifyToggle, setNotifyToggle] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [status, setStatus] = useState("");
  const { width = 0 } = useWindowSize();
  const aiAssistantRef = useRef<HTMLDivElement>(null);
  const childRef: any = useRef();
  const dispatch = useDispatch()
  const userId = getUserId();
  const { data: profilePictureData, isLoading: isLoadingProfilePicture } =
    useGetUserProfilePicture(userId);

  function MobileHamburgerMenu() {
    const toggleSidebarMenu = () => {
      if (window.innerWidth < 1023) {
        const sidebar = document.querySelector("#application-sidebar");
        const sidebarOverlay = document.querySelector(
          "#application-sidebar-backdrop"
        );
        sidebar?.classList.toggle("translate-x-0");
        sidebarOverlay?.classList.toggle("hidden");
      }
    };

    return (
      <div className="flex gap-4 me-5 ">
        <button
          type="button"
          className="text-gray-500 hover:text-gray-600"
          aria-label="Toggle navigation"
          onClick={toggleSidebarMenu}
        >
          <span className="sr-only">Toggle Navigation</span>
          <HamburgerLineIcon
            className="h-4 w-4 md:h-7 md:w-7 mt-3 md:mt-6"
            onClick={handleToggle}
          />
        </button>
      </div>
    );
  }
  const { info:userInfo, openTicket} = useSelector((state: RootState) => state.user);
  useEffect(() => {
    const iframeExist = document.querySelector(".embedded-messaging iframe");

    if (iframeExist) {
      const jsonString = localStorage.getItem("00DDx0000008rlq_WEB_STORAGE");
      if (jsonString) {
        const data = JSON.parse(jsonString);
        // Assuming there is only one key, or you need the first key
        const keys = Object.keys(data);
        console.log({ keys });

        if (keys.length > 0) {
          const jwt = keys[0];
          const uuid = keys[1];
          console.log({ uuid });
          console.log({ jwt });
          const token = jwt.length > 5 ? jwt : uuid;
          fetch(
            `https://findmyoptions--fmodev2.sandbox.my.salesforce-scrt.com/iamessage/v1/conversation/${token}`,
            {
              method: "DELETE",
            }
          )
            .then((response) => response.json())
            .then((data) => console.log("Delete successful:", data))
            .catch((error) => console.error("Error deleting the item:", error));
        } else {
          console.log("No data found.");
        }
      } else {
        console.log("No item found in localStorage.");
      }
    }

    console.log({ iframeExist });
  }, []);

  useEffect(() => {
    const handleNotifications = () => {
      console.log(cometd.getStatus());
      initCometD();

      // initCometD(() => {
      //   subscribeToChannel("/event/Notification_Event__e", (message: any) => {
      //     console.log("New notification:", message.data);
      //   });
      // });

      return () => {
        // Cleanup cometd connection on component unmount
        cometd.disconnect();
      };
    };
    handleNotifications();
  }, [cometd]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        aiAssistantRef.current &&
        !aiAssistantRef.current.contains(event.target)
      ) {
        setHover(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //dont delete this
  // useEffect(() => {
  //   initCometD(() => {
  //     subscribeToChannel("/notifications", (message: any) => {
  //       console.log("New notification:", message.data);
  //     });
  //   });

  //   return () => {
  //     // Cleanup cometd connection on component unmount
  //     cometd.disconnect();
  //   };
  // }, []);

  const handleOpenChat = async () => {
    setOpenChat(false);
    setChatToggle(false);
    setStatus("");
    const { embeddedservice_bootstrap } = window as any;
    console.log(embeddedservice_bootstrap);
    if (embeddedservice_bootstrap && embeddedservice_bootstrap.utilAPI) {
      await embeddedservice_bootstrap.utilAPI
        .launchChat()
        .then((success: any) => {
          const embeddedMessaging = document.querySelector(
            ".embedded-messaging"
          ) as HTMLElement;
          if (embeddedMessaging) {
            embeddedMessaging.style.display = "";
          }
          console.log({ success });
          const iframe = document.querySelector(".embedded-messaging iframe");
          if (iframe) {
            iframe.classList.add("to-position");
          }
          console.log({ iframe });
        })
        .catch((error: any) => {
          console.log({ error });
          // Error handler used to perform actions
          // if the chat client fails to launch.
          // For example, create a method that hides the launch chat button.
        })
        .finally(() => {
          console.log("finally");
          // Finally handler used to perform any clean-up actions
          // regardless of whether the chat client launches successfully or not.
          // For example, create a method that logs the user’s attempt to chat.
          // logEndUserAttemptToChat();
        });
    }
  };
  function handleClickOutside() {
    // const embeddedMessaging = document.querySelector(
    //   ".embedded-messaging"
    // ) as HTMLElement | null;
    // const embeddedMessagingFrame = document.querySelector(
    //   ".embeddedMessagingSiteContextFrame"
    // ) as HTMLElement | null;
    // const embeddedMessagingFilePreviewFrame = document.querySelector(
    //   ".embeddedMessagingFilePreviewFrame"
    // ) as HTMLElement | null;
    // if (
    //   embeddedMessaging &&
    //   !embeddedMessaging.contains(event.target as Node)
    // ) {
    //   // Click was outside `.embedded-messaging`, so hide it
    //   embeddedMessaging.ariaHidden = "true";
    //   if (embeddedMessagingFrame) embeddedMessagingFrame.ariaHidden = "true";
    //   if (embeddedMessagingFilePreviewFrame)
    //     embeddedMessagingFilePreviewFrame.ariaHidden = "true";
    // }
    const embeddedMessaging = document.querySelector(
      ".embedded-messaging"
    ) as HTMLElement;
    if (embeddedMessaging) {
      embeddedMessaging.style.display = "none";
    }
  }
  function setupOutsideClickListener() {
    

    // Add the event listener to the document
    if (window.innerWidth > 768) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  }


  //   setupOutsideClickListener();
  // function handleClickOutside(event: any) {
  //   const embeddedMessaging = document.querySelector(
  //     ".embedded-messaging"
  //   ) as HTMLElement;
  //   if (embeddedMessaging) {
  //     embeddedMessaging.style.display = "none";
  //   }
  // }

  // function setupOutsideClickListener() {
  //   // Check if the screen width is greater than 768 pixels
  //   if (window.innerWidth > 768) {
  //     // Add the event listener to the document only if the screen is large
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     // Ensure the event listener is removed if the screen is small
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }
  // }

  // Setup listener initially
  setupOutsideClickListener();
  useEffect(()=>{
    handleClickOutside()
  },[])

  // Add resize event handler to handle window resizing
  // window.addEventListener("resize", function () {
  //   setupOutsideClickListener();
  // });

  const openSupportTicket = () => {
    setOpenChat(false);
    setChatToggle(true);
    setStatus("case");
    const embeddedMessaging = document.querySelector(
      ".embedded-messaging"
    ) as HTMLElement;
    if (embeddedMessaging) {
      embeddedMessaging.style.display = "none";
    }
  }
  useEffect(()=>{
    if(openTicket === "1"){
      openSupportTicket()
      dispatch(changeTicketView(null))
    }
  },[openTicket])
  if (isLoadingProfilePicture) return <Loading />;
  return (
    <>
      <div className="flex justify-end mt-5">
        {
          <ChatHelp
            childRef={childRef}
            setChatToggle={setChatToggle}
            chatToggle={chatToggle}
            openChat={openChat}
            setOpenChat={setOpenChat}
            curr={status}
          />
        }
        {notifyToggle && (
          <Notification
            setNotifyToggle={setNotifyToggle}
            notifyToggle={notifyToggle}
          />
        )}
      </div>

      <header
        className={`top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-[48] w-full backdrop-blur-lg py-2 sm:py-4 ${
          toggle ? "lg:ps-44" : "lg:ps-16"
        }`}
      >
        <nav
          className="flex items-center w-full px-4 mx-auto basis-full sm:px-6 lg:px-8 xl:px-12"
          aria-label="Global"
        >
          {(!toggle || width < 1023) && (
            <div className="flex justify-between items-center">
              {width < 1023 && <MobileHamburgerMenu />}
              <div className="me-5 ">
                <Link
                  className="flex-none text-xl font-semibold"
                  to="/listing/landing-page"
                  aria-label="Brand"
                >
                  <LogoColoured className="size-14 md:size-24" />
                </Link>
              </div>
            </div>
          )}

          <div className="flex items-center justify-end  w-full ms-auto sm:justify-between sm:gap-x-3 sm:order-3">
            <div className="hidden w-full sm:block " ref={aiAssistantRef}>
              <div className="ml-12">
                <SearchField
                  placeholderText="Ask me anything car related!"
                  className="text-lg rounded-4xl  w-[300px] md:w-[360px] xl:w-[400px]"
                  value={""}
                  classNames={{ inputWrapper: "bg-transparent" }}
                  onClick={() => setHover(true)}
                />

                <NewChatBotTurbo
                  onCloseCallBack={() => {
                    setHover(false);
                  }}
                  isHover={isHover}
                  userName={userInfo?.firstName}
                  from="dealer"
                />
              </div>
            </div>

            <div className="flex flex-row items-center justify-end gap-2 lg:gap-6 w-full text-sm text-[#313131] font-[Metropolis] ">
              <div className="flex items-center gap-4">
                <Popover placement="bottom" className="w-full" showArrow={true}>
                  <PopoverTrigger>
                    <button
                      type="button"
                      className="outline-none inline-flex items-center justify-center text-sm font-semibold text-gray-800 border border-transparent rounded-full gap-x-2 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                    >
                      <NotificationIcon className="size-7 lg:size-8" />
                      <span className="hidden lg:block">Notifications</span>
                    </button>
                  </PopoverTrigger>
 {/* for mobile need model */}
                  <PopoverContent className=" notification-container">
                    <div className=" w-full h-[700px] lg:h-[400px] ">
                      
                      {notifications?.map((notifcation, idx) => (
                        <div key={idx} className="w-full">
                          <div className="notification">
                            <div className="w-[50px]">
                              <Image
                                alt="avatar-image"
                                removeWrapper
                                radius="lg"
                                className="z-0  w-[9rem]  h-[54px] inline-block size-[38px] rounded-full object-fill"
                                loading="eager"
                                src={
                                  profilePictureData
                                    ? `${env.BASE_API_URL}User/image/${userId}`
                                    : "/assets/images/avatar/user.jpg"
                                }
                              />
                              {/* <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/icon/fmo_avatar.svg"
                                }
                                alt="Avatar"
                              /> */}
                            </div>
                            <div className="notification-content">
                              <h3 className="notification-title text-[#313131]-700">
                                {notifcation.title}
                              </h3>
                              <p className="notification-message text-justify">
                                {notifcation.message}
                              </p>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
              <Dropdown>
                <DropdownTrigger>
                  <button
                    type="button"
                    className="outline-none inline-flex items-center justify-center text-sm font-semibold text-gray-800 border border-transparent rounded-full gap-x-2 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    <HelpOutlineIcon className="size-7 lg:size-8" />
                    <span className="hidden lg:block">Help</span>
                  </button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownSection>
                    <DropdownItem
                      onClick={openSupportTicket}
                    >
                      <p className="text-base font-[500] w-full">
                        Create Support ticket
                      </p>
                    </DropdownItem>
                    <DropdownItem onClick={handleOpenChat}>
                      <p className="text-base font-[500] w-full">
                        24/7 Chat Support
                      </p>
                    </DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
                <DropdownTrigger>
                  <button
                    type="button"
                    className="outline-none inline-flex items-center justify-center text-sm font-semibold text-gray-800 border border-transparent rounded-full gap-x-2 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                  >
                    <HelpOutlineIcon className="size-7 lg:size-8" />
                    <span className="hidden lg:block">Help</span>
                  </button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownSection>
                    <DropdownItem
                      onClick={() => {
                        setOpenChat(false);
                        setChatToggle(true);
                        setStatus("case");
                        const embeddedMessaging = document.querySelector(
                          ".embedded-messaging"
                        ) as HTMLElement;
                        if (embeddedMessaging) {
                          embeddedMessaging.style.display = "none";
                        }
                      }}
                    >
                      <p className="text-base font-[500] w-full">
                        Create Support Chat ticket
                      </p>
                    </DropdownItem>
                    <DropdownItem onClick={handleOpenChat}>
                      <p className="text-base font-[500] w-full">
                        24/7 Chat Support
                      </p>
                    </DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
              <div className="bg-[#313131] h-[44px] w-[1px] hidden lg:block" />
              <div className="">
                <ProfileDropdown
                  name={userInfo?.firstName || "user"}
                  userId={userId}
                  profilePictureData={profilePictureData}
                />
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
