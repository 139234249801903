import { SVGProps } from "react";

export const NotificationIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="mi:notification">
      <path
        id="Vector"
        d="M14.7965 4.7368C15.0144 4.19826 15.3881 3.73705 15.8698 3.4123C16.3515 3.08756 16.9193 2.91406 17.5002 2.91406C18.0812 2.91406 18.6489 3.08756 19.1306 3.4123C19.6123 3.73705 19.9861 4.19826 20.204 4.7368C22.3605 5.32988 24.2627 6.61449 25.6184 8.39333C26.9742 10.1722 27.7085 12.3469 27.7086 14.5835V21.4333L30.3802 25.4408C30.5268 25.6604 30.6109 25.9157 30.6237 26.1794C30.6365 26.4431 30.5775 26.7053 30.453 26.9381C30.3284 27.1709 30.143 27.3655 29.9165 27.5012C29.69 27.6369 29.4309 27.7085 29.1669 27.7085H22.5533C22.3778 28.9234 21.7703 30.0344 20.8423 30.838C19.9143 31.6415 18.7278 32.0838 17.5002 32.0838C16.2727 32.0838 15.0862 31.6415 14.1582 30.838C13.2301 30.0344 12.6227 28.9234 12.4471 27.7085H5.83355C5.56953 27.7085 5.31045 27.6369 5.08396 27.5012C4.85746 27.3655 4.67204 27.1709 4.54749 26.9381C4.42293 26.7053 4.36391 26.4431 4.37672 26.1794C4.38953 25.9157 4.47369 25.6604 4.62022 25.4408L7.29189 21.4333V14.5835C7.29189 9.8818 10.4711 5.92097 14.7965 4.7368ZM15.4381 27.7085C15.5887 28.1352 15.868 28.5048 16.2375 28.7662C16.6069 29.0276 17.0484 29.168 17.5009 29.168C17.9535 29.168 18.395 29.0276 18.7644 28.7662C19.1339 28.5048 19.4131 28.1352 19.5638 27.7085H15.4367H15.4381ZM17.5002 7.2918C15.5663 7.2918 13.7117 8.06003 12.3442 9.42748C10.9768 10.7949 10.2086 12.6496 10.2086 14.5835V21.8751C10.2086 22.1632 10.1234 22.4448 9.96355 22.6845L8.55918 24.7918H26.4398L25.0354 22.6845C24.8761 22.4447 24.7914 22.163 24.7919 21.8751V14.5835C24.7919 12.6496 24.0237 10.7949 22.6562 9.42748C21.2888 8.06003 19.4341 7.2918 17.5002 7.2918Z"
        fill="#313131"
      />
      <circle id="Ellipse 1" cx="24" cy="9" r="3" fill="#FF0A0A" />
    </g>
  </svg>
);
